<template>
  <v-row class="py-4 px-8">
    <v-col cols="12" v-if="!loading">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between">
          <v-chip
            label
            color="blue-grey lighten-5"
            class="pa-5"
          >
            {{ semanas[filtro_semana].name }}
            <v-btn 
              small 
              :disabled="filtro_semana <= 0" 
              icon
              @click="formerWeek"
            >
              <v-icon  color="blue-grey">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              small 
              :disabled="filtro_semana >= 4" 
              icon
              @click="nextWeek"
            >
              <v-icon color="blue-grey">mdi-chevron-right</v-icon>
            </v-btn>
          </v-chip>          
        </v-col>
      </v-row>    
      <template v-for="(dia,i) in itemsProg">
        <dia-visitas :dia="dia" :key="'progv-'+i" />
      </template>
    </v-col>
    <v-col cols="12" v-else>
      <v-skeleton-loader
        type="article, article"
      />
    </v-col>
    <v-col cols="12" v-if="itemsProg.length === 0">
      <div class="text-center pa-4">
        <span class="blue-grey--text font-italic">No se encontraron Registros disponibles</span>
      </div>
    </v-col>
  </v-row> 
</template>
<script>
import moment from 'moment'
import es from 'moment/locale/es'
import { get } from 'vuex-pathify'
moment.locale('es', es);
export default {
  name: 'ProgramaActual',
   components:{
    DiaVisitas: () => import(
      /* webpackChunkName: "dia-visitas" */
      './DiaVisitasOld.vue'
    ),
   },
  data: () => ({
    filtro: '',
    filtro_semana: 0,
    loading: false,
    dataProgramaOld: []
    
  }),
  created() {
    this.getProgVisita();
  },
  methods:{
    nextWeek() {
      if (this.filtro_semana + 1 <= 4) this.filtro_semana += 1
    },
    formerWeek () {
      if (this.filtro_semana - 1 >= 0) this.filtro_semana -= 1
    },
    async getProgVisita() {
      this.loading = true;
      try {
        const prog_visita = await this.$api.get(`vendedores/${this.Ven}/programavisita`);
        this.dataProgramaOld = prog_visita.data.data;
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Programa de Visita.',
          'error',
        )
      } 
      this.loading = false;
    },
  },
  computed:{
    Ven: get('route/params@cod'),
    semanas() {
      let weeks = [{
        name:  moment().startOf('week').day(1).format("DD") + ' - ' + moment().startOf('week').day(5).format("DD") + moment().startOf('week').day(1).format(" MMMM, YYYY"),
        start:  moment().startOf('week').day(1).format("YYYY-MM-DD"),
        end:  moment().startOf('week').day(5).format("YYYY-MM-DD"),
      }];

      for (let index = 1; index < 5; index++) {
        weeks.push({
          name:  moment().add(index, 'w').startOf('week').format("DD") + ' - ' + moment().add(index, 'w').startOf('week').day(5).format("DD") +  moment().add(index, 'w').startOf('week').format(" MMMM, YYYY"),
          start: moment().add(index, 'w').startOf('week').format("YYYY-MM-DD"),
          end:   moment().add(index, 'w').startOf('week').day(5).format("YYYY-MM-DD"),
        })
        
      }

      return weeks;
    },
    itemsProg() {
      let datos = [];

      const fechas = Object.keys(this.dataProgramaOld);
      if(fechas.length > 0) {
        for (const key in fechas) {
          const format = fechas[key].split('-')
          datos.push({
            fecha: format[2]+'-'+format[1]+'-'+format[0],
            clientes: this.dataProgramaOld[fechas[key]]
          })
        }
        return datos.filter(data => data.clientes.length > 0 && moment(data.fecha).isBetween(this.semanas[this.filtro_semana].start, this.semanas[this.filtro_semana].end, undefined, '[]'));
      }

      return [];
    },
    
  }

}
</script>
<style lang="sass">
.titulo-visita
  background-color: #fafafa
  display: flex !important
  -webkit-box-align: center !important
  -ms-flex-align: center !important
  align-items: center !important
  padding: 5px 10px
  line-height: 2.5em
  border: solid
  border-width: 0 0 0 0.15rem
  border-color: #43b02a !important

</style>